// src/App.jsx
import React, { useState, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  Home,
  About,
   Academy,
  Products,
  Event,
  Contact,
  Login,
  Register,
  WebDev,
  SoftwareDev,
  ProductDesign,
  Pentesting,
  DigitalForensics,
  Careers,
  BackendDev,
  ItConsulting,
  CloudSecurityArchitect,
  CybersecurityRiskAnalyst,
  Designer,
  DevOpsEngineer,
  FrontendDev,
  MachineLearningEngineer,
  MobileAppsDeveloper,
  NetworkSecurityAnalyst,
  PenetrationTester,
  Campus,
} from "./pages/index.jsx";
import TestimonialsPart from "./components/micros/testimony/TestimonialsComponent.jsx";
import LandingLayout from "./components/wHTA_Academy/layouts/LandingLayout.jsx";
import Layout from "./Layout.jsx";
import AcademyLayout from "./components/wHTA_Academy/layouts/AcademyLayout.jsx";
import SupportPage from "./components/wHTA_Academy/pages/Support.jsx";
import Payment from "./components/wHTA_Academy/pages/Payment.jsx";
import BrowseCoursesPage from "./components/wHTA_Academy/pages/BrowseCourses.jsx";
import CybersecuritySection from "./components/wHTA_Academy/components/browseCourses/CyberSecurity/Cybersec.jsx";
import EmergingTechSection from "./components/wHTA_Academy/components/browseCourses/EmergingTech/Emerging_T.jsx";
import SoftwaredevSection from "./components/wHTA_Academy/components/browseCourses/Software-dev/Softwaredevelopment.jsx";
import LoginPage from "./components/wHTA_Academy/pages/Login.jsx";
import SignUpLayout from "./components/wHTA_Academy/layouts/SignUpLayout.jsx";
import SignUpPage from "./components/wHTA_Academy/pages/SignUp.jsx";
import { StepperProvider } from "./components/wHTA_Academy/components/Sign_Up/createAccountStepper/StepperContext.jsx";
import HealthCare from "./pages/industries/healthcare/Healthcare.jsx";
import SoftwareComponent from "./components/sections/industries/healthcare/advantages/software.jsx";
import Finance_ from "./pages/industries/finance/Finance.jsx";
import Retail_ from "./pages/industries/retail/Retail.jsx";
import Technology from "./pages/industries/technology/Tech.jsx";
import Loadercomponent from "./components/micros/Loader/Loader.jsx";
import Sidebar from "./components/wHTA_Academy/components/Students/Sidebar/sidebar.jsx";
import StudentLayout from "./components/wHTA_Academy/layouts/StudentLayout.jsx";
import AdminLayout from "./components/wHTA_Academy/layouts/AdminLayout.jsx";
import InstructorLayout from "./components/wHTA_Academy/layouts/InstructorLayout.jsx";
import Student_Dashboard from "./components/wHTA_Academy/pages/Students/Dashboard.jsx";
import Instructor_Dashboard from "./components/wHTA_Academy/pages/Instructors/Dashboard.jsx";
import Admin_Dashboard from "./components/wHTA_Academy/pages/Admin/Dashboard.jsx";
import Student_Transaction from "./components/wHTA_Academy/pages/Students/Student_Transactions.jsx";
import Admin_Transactions from "./components/wHTA_Academy/pages/Admin/adminTransactions.jsx";
import Admin_Courses from "./components/wHTA_Academy/pages/Admin/courses.jsx";
import Instructor_Profile from "./components/wHTA_Academy/pages/Instructors/Profile.jsx";
import Admin_Profile from "./components/wHTA_Academy/pages/Admin/AdminProfile.jsx";

import Submissions from "./components/wHTA_Academy/pages/Instructors/Submissions.jsx";
import Forums from "./components/wHTA_Academy/pages/Students/Forums.jsx";

import Instructor_LiveClasses from "./components/wHTA_Academy/pages/Instructors/LiveClasses.jsx";
import Student_LiveClasses from "./components/wHTA_Academy/pages/Students/LiveClasses.jsx";

import Assignment from "./components/wHTA_Academy/pages/Instructors/Assignment.jsx";
import ProfilePage from "./components/wHTA_Academy/components/Students/Profile/Profile.jsx";

import Assignments from "./components/wHTA_Academy/pages/Students/Assignments.jsx";
// import Campus from "./pages/wHTaCampus/Campus.jsx";
import Student_Page from "./components/wHTA_Academy/pages/Admin/students.jsx";
import Insructors_Page from "./components/wHTA_Academy/pages/Admin/instructors.jsx";

import VerifyPage from "./components/wHTA_Academy/pages/Verify-OTP.jsx";
import Create_New_Password from "./components/wHTA_Academy/pages/createNewPass.jsx";
import { NotificationProvider } from "./components/micros/Notifications/Notifications.jsx";
import Student_Courses from "./components/wHTA_Academy/pages/Students/MyCourses.jsx";
import Class_Schedule from "./components/wHTA_Academy/components/Scheduler/Class_Scheduler.jsx";
import Instructor_Student_Page from "./components/wHTA_Academy/pages/Instructors/Students.jsx";
import Portfolio from "./components/sections/portfolio/Portfolio.jsx";
import Cybersecurity from "./components/sections/Cybersecurity/cybersec.jsx";
import ContactUs from "./components/sections/ContactUs/contactus.jsx";
import Partners from "./components/sections/partners/partners.jsx"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "about", element: <About /> },
      { path: "academy", element: <Academy /> },
      { path: "products", element: <Products /> },
      { path: "events", element: <Event /> },
      { path: "support", element: <SupportPage /> },
      { path: "careers", element: <Careers /> },
      { path: "BackendDev", element: <BackendDev /> },
      { path: "CloudSecurityArchitect", element: <CloudSecurityArchitect /> },
      {
        path: "CybersecurityRiskAnalyst",
        element: <CybersecurityRiskAnalyst />,
      },
      { path: "Designer", element: <Designer /> },
      { path: "DevOpsEngineer", element: <DevOpsEngineer /> },
      { path: "FrontendDev", element: <FrontendDev /> },
      { path: "MachineLearningEngineer", element: <MachineLearningEngineer /> },
      { path: "MobileAppsDeveloper", element: <MobileAppsDeveloper /> },
      { path: "NetworkSecurityAnalyst", element: <NetworkSecurityAnalyst /> },
      { path: "PenetrationTester", element: <PenetrationTester /> },
      { path: "web-development", element: <WebDev /> },
      { path: "pentesting", element: <Pentesting /> },
      { path: "product-design", element: <ProductDesign /> },
      { path: "software-dev", element: <SoftwareDev /> },
      { path: "digital-forensics", element: <DigitalForensics /> },
      { path: "login", element: <Login /> },
      { path: "sign-up", element: <Register /> },
      { path: "healthcare", element: <HealthCare /> },
      { path: "finance", element: <Finance_ /> },
      { path: "retail", element: <Retail_ /> },
      { path: "technology", element: <Technology /> },
      { path: "SoftwareComponent", element: <SoftwareComponent /> },
      { path: "itconsulting", element: <ItConsulting /> },
      { path: "wHTa-campus", element: <Campus /> },
      { path: "portfolio", element: <Portfolio /> },
      {path:"cybersecurity", element: <Cybersecurity />},
      {path:"contact-us", element: <ContactUs />},
      {path:"partners", element: <Partners />}


    ],
  },
  /* {
    path: "elearning",
    element: <AcademyLayout />,
    children: [
      { index: true, element: <LandingLayout /> },
      { path: "payment", element: <Payment /> },
      { path: "support", element: <SupportPage /> },
      { path: "login", element: <LoginPage /> },
      { path: "verify-otp", element: <VerifyPage /> },
      { path: "create-new-password", element: <Create_New_Password /> },
      { path: "softwaredev", element: <SoftwaredevSection /> },
      { path: "cybersecurity", element: <CybersecuritySection /> },
      { path: "emergingtech", element: <EmergingTechSection /> },
      { path: "browse", element: <BrowseCoursesPage /> },
    ],
  },
  {
    path: "elearning/student",
    element: <StudentLayout />,
    children: [
      { index: true, element: <Student_Dashboard /> },

      { path: "transactions", element: <Student_Transaction /> },
      { path: "dashboard", element: <Student_Dashboard /> },
      { path: "profile", element: <ProfilePage /> },
      { path: "forum", element: <Forums /> },

      { path: "liveClasses", element: <Student_LiveClasses /> },
      // { path: "liveClasses", element: <Class_Schedule/> },
      { path: "assignments", element: <Assignments /> },
      { path: "transactions", element: <Student_Transaction /> },
      { path: "forum", element: <Forums /> },
      { path: "mycourses", element: <Student_Courses /> },
    ],
  },
  {
    path: "elearning/administrator",
    element: <AdminLayout />,
    children: [
      { index: true, element: <Admin_Dashboard /> },

      { index:true, path: "dashboard", element: <Admin_Dashboard /> },
      { path: "transactions", element: <Admin_Transactions /> },
      { path: "courses", element: <Admin_Courses /> },
      { path: "profile", element: <Admin_Profile /> },
      { path: "students", element: <Student_Page /> },
      { path: "all-instructors", element: <Insructors_Page /> },
      { path: "forum", element: <Forums /> },
      { path: "live-Classes", element: <Instructor_LiveClasses /> },
      { path: "submissions", element: <Submissions /> },
      { path: "assignment", element: <Assignment /> },
    ],
  },
  {
    path: "elearning/instructor",
    element: <InstructorLayout />,
    children: [
      { index: true, element: <Instructor_Dashboard /> },
      { path: "dashboard", element: <Instructor_Dashboard /> },
      { path: "profile", element: <Instructor_Profile /> },
      { path: "students", element: <Instructor_Student_Page /> },
      { path: "courses", element: <Admin_Courses /> },
      { path: "live-Classes", element: <Instructor_LiveClasses /> },
      { path: "submissions", element: <Submissions /> },
      { path: "assignment", element: <Assignment /> },
       { path: "forum", element: <Forums /> },
    ],
  },
  {
    path: "elearning/signup",
    element: <SignUpLayout />,
    children: [{ index: true, element: <SignUpPage /> }],
  },*/
]);

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loadercomponent />;
  }

  return (
    <NotificationProvider>
      <StepperProvider>
        <RouterProvider router={router} />
      </StepperProvider>
    </NotificationProvider>
  );
}

export default App;
