const ContactUs = () => {
  return (
    <div className="min-h-screen w-full px-4">
      <div className="my-12 h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10"></div>
      <h1 className="text-3xl md:text-4xl font-bold text-center text-[#1C0A6D] mt-16 md:mt-24 mb-4">
        Contact Us
      </h1>
      <p className="text-center font-semibold">
        Our Team would love to hear from you!
      </p>
      <div className="flex flex-col md:flex-row gap-6 md:gap-4 mx-auto w-full max-w-4xl pt-10">
        <div className="flex flex-col items-center justify-center w-full md:w-1/3 p-8 bg-gray-50 rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-105">
          <img src="/tel.svg" alt="Phone Icon" className="w-6 h-6 mb-4" />
          <span className="text-[#1C0A6D] text-lg">+(254) 745 699 969</span>
        </div>
        <div className="flex flex-col items-center justify-center w-full md:w-1/3 p-8 bg-gray-50 rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-105">
          <img
            src="/whatsapp.svg"
            alt="WhatsApp Icon"
            className="w-6 h-6 mb-4"
          />
          <span className="text-[#1C0A6D] text-lg">+(254) 782 499 113</span>
        </div>
        <div className="flex flex-col items-center justify-center w-full md:w-1/3 p-8 bg-gray-50 rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-105">
          <img src="/mail.svg" alt="Mail Icon" className="w-6 h-6 mb-4" />
          <span className="text-[#1C0A6D] text-lg">hello@whtanetworks.com</span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
