import { Link } from "react-router-dom";

const Partners = () => {
  return (
    <div className="w-full px-6 py-12 bg-gray-50 ">
      {/* Divider */}
 <div className="my-12 h-0.5 border-t-0 bg-neutral-300 dark:bg-white/10"></div>

      {/* Why Partner With Us Section */}
      <section className="max-w-5xl mx-auto text-center my-12">
        <h2 className="text-2xl md:text-3xl font-semibold text-purple-darker">
          Why Partner With Us?
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mt-4 text-lg">
          At wHTa Networks, we believe in the power of collaboration. Partnering with us means gaining access
          to innovative technology, expert solutions, and a network of professionals committed to driving success.
        </p>
      </section>

      {/* Benefits Section with Cards */}
      <section className="max-w-5xl mx-auto text-center my-12">
        <h2 className="text-2xl md:text-3xl font-semibold text-purple-darker mb-8">
          Benefits of Partnering
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[
            { title: "Expert Collaboration", desc: "Work with industry-leading professionals to scale your business." },
            { title: "Advanced Security", desc: "Benefit from cutting-edge cybersecurity and risk management." },
            { title: "Scalable Solutions", desc: "Tailored business solutions that grow with your needs." },
            { title: "Priority Support", desc: "Get exclusive access to dedicated customer support." },
            { title: "Technology Access", desc: "Gain insights and access to the latest innovations." },
            { title: "Strategic Insights", desc: "Leverage expert guidance to optimize business performance." }
          ].map((benefit, index) => (
            <div key={index} className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6 hover:shadow-xl transition">
              <h3 className="text-lg font-semibold text-purple-darker">{benefit.title}</h3>
              <p className="text-gray-600 dark:text-gray-300 mt-2">{benefit.desc}</p>
            </div>
          ))}
        </div>
      </section>

   
      

      {/* Our Partners Section */}
      <section className="text-center my-12">
        <h2 className="text-2xl md:text-3xl font-semibold text-purple-darker mb-8">
          Our Trusted Partners
        </h2>
        <div className="flex justify-center items-center">
          <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6 flex justify-center items-center">
            <img src="/learning247.png" alt="Learning247" className="h-10 md:h-16" />
          </div>
        
        </div>
      </section>


      <section className="max-w-5xl mx-auto text-center mt-12 mb-2">
        <h2 className="text-2xl md:text-3xl font-semibold text-purple-darker">
          Ready to Partner With Us?
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mt-8 mb-8 text-lg">
          Take the next step in building a strong and successful partnership. Let's work together!
        </p>
        <Link to="/contact-us">
          <button className="bg-purple-darker text-white px-4 py-2 rounded-xl  font-semibold hover:bg-purple-normal-active transition">
            Contact Us
          </button>
        </Link>
      </section>
    </div>
  );
};

export default Partners;
