import React, { useState } from "react";
import { ListLink, Input, SingleClick } from "../../micros/index";
import { useNotification } from "../../micros/Notifications/Notifications";
import { useDispatch } from "react-redux";
import { credentials } from "../../../auth";
import { config } from "../../../auth";
import Terms from "../../micros/lists/Terms";

function Footer2() {
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSections, setModalSections] = useState([]);

  const { setSuccessNotification, setErrorNotification, setInfoNotification } =
    useNotification();
  const dispatch = useDispatch();

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const data = { email: subscribeEmail };
    try {
      const response = await fetch(
        `${config.baseURL}/subscription/subscribe/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${credentials}`,
          },
          body: JSON.stringify(data),
        }
      );
      console.log("json:", JSON.stringify(data));
      if (response.status != 201) {
        await dispatch(setInfoNotification("Email already subscribed"));
        throw new Error("Network response was not ok");
      } else {
        console.log("Email Subscribed successfully");
        console.log("Response", response);
        await dispatch(setSuccessNotification("Email Subscribed successfully"));
      }
    } catch (error) {
      console.error("Subscription error:", error);
    }
  };

  const productsData = [
    { text: "wHTa Academy", link: "/elearning" },
    { text: "wHTa Campus", link: "#" },
    {text:"Shuleni"},
  ];

  const cybersecurityData = [
    { text: "Corporate Training", link: "/service" },
    { text: "Cybersecurity Awareness", link: "/pentesting" },
    { text: "Digital Forensics Investigation", link: "/digital-forensics" },
    { text: "Penetration Testing", link: "/pentesting" },
    { text: "Cyber Attack Simulation", link: "/pentesting" },
    { text: "Cybersecurity Strategy and Compliance", link: "/pentesting" },
    { text: "Network and Cloud Security", link: "/pentesting" },
  ];

  const softwareDevelopmentData = [
    { text: "Application Development", link: "/software-dev" },
    { text: "Website Development", link: "/web-development" },
    { text: "Product Design", link: "/product-design" },
    { text: "DevOps", link: "/software-dev" },
    { text: "Microservices", link: "/software-dev" },
    { text: "Business Intelligence Analytics", link: "/digital-forensics" },
  ];

  const getInTouchData = [
    {
      icon: "icons/x.svg",
      alt: "X",
      text: "X(Twitter)",
      link: "https://x.com/wHTaNetworks",
    },
    {
      icon: "/icons/whatsapp-logo-icon.svg",
      alt: "whatsapp",
      text: "Whatsapp",
      link: "https://wa.me/c/254782499113",
    },
    {
      icon: "icons/linkedin.svg",
      alt: "linkedin",
      text: "LinkedIn",
      link: "https://www.linkedin.com/company/whta-networks/",
    },
  ];

  const contactUsData = [
    {
      text: "Terms of Service",
    },
    {
      text: "Privacy Policy",
    },
    {
      text: "hello@whtanetworks.com",
    },
    {
      text: "(+254) 782499113",
    },
  ];

  const handleShowModal = (title, sections) => {
    setModalTitle(title);
    setModalSections(sections);
    setShowModal(true);
  };

  const termsSections = [
    {
      subtitle: "Introduction",
      content:
        "Welcome to wHTa Networks Academy. This policy outlines the rules and guidelines for all participants, including students, instructors, and administrators, to ensure a productive and respectful learning environment. By enrolling in or tutoring at wHTa Networks Academy, all participants agree to adhere to these policies.",
    },
    {
      subtitle: "General Policies",
      content: `
        2.1 Code of Conduct
        • Respect: All members of the wHTa Networks Academy community are expected to treat others with respect and courtesy.
        • Integrity: Academic honesty is essential. Plagiarism, cheating, and any form of dishonesty are strictly prohibited.
        • Inclusivity: We are committed to providing an inclusive and welcoming environment for all participants. Discrimination or harassment of any kind will not be tolerated.
        2.2 Enrollment and Participation
        • Enrollment: Students must complete the enrollment process to participate in courses. Enrollment deadlines will be communicated clearly.
        • Participation: Students are expected to actively participate in all course activities, including assignments, assessments, and discussions.
        • Attendance: Regular attendance is required for all live sessions and mandatory activities. Absences should be communicated to the instructor in advance.
      `,
    },
    {
      subtitle: "Academic Policies",
      content: `
        3.1 Coursework and Assignments
        • Submission Deadlines: Assignments must be submitted by the specified deadlines. Late submissions may be subject to penalties unless prior arrangements have been made with the instructor.
        • Original Work: All coursework must be the student’s own original work. Proper citation of sources is required.
        • Feedback: Instructors will provide timely feedback on assignments and assessments to help students improve their skills and understanding.
        3.2 Assessments and Skill Tests
        • Integrity in Testing: Students must complete assessments and skill tests independently unless collaboration is explicitly allowed by the instructor.
        • Retakes: Policies on retakes for assessments and skill tests will be determined by the instructor and communicated at the start of the course.
        • Grading: Grading criteria will be transparent and consistent. Students can request clarification on their grades if needed.
      `,
    },
    {
      subtitle: "Technical Policies",
      content: `
        4.1 Platform Usage
        • Access: Students and instructors are responsible for maintaining access to the eLearning platform and ensuring their equipment meets the technical requirements.
        • Security: Users must keep their login credentials secure and report any security breaches immediately.
        4.2 Technical Support
        • Support: Technical support is available to all participants. Issues should be reported through the designated support channels.
        • Updates: The platform will undergo regular maintenance and updates. Scheduled downtime will be communicated in advance.
      `,
    },
    {
      subtitle: "Financial Policies",
      content: `
        5.1 Payments and Payment Plans
        • Tuition Fees: Tuition fees must be paid according to the selected payment plan. The available payment plans are:
        • Upfront/Full Payment: Learners pay the entire course fee upfront and gain immediate access to all three modules of the course.
        • 2-Month Installment Plan: Learners pay the course fee in two equal installments over two months. Access to the next module requires the successful payment of the installment.
        • 3-Month Installment Plan: Learners pay the course fee in three equal installments over three months. Access to the next module requires the successful payment of the installment.
        5.2 Access to Modules
        • Full Payment: Learners who pay the full course fee upfront will have access to all three modules immediately.
        • Installment Plans: Learners on the installment plans will need to make their payments on time to unlock each subsequent module. If a payment is missed, access to the next module will be restricted until the payment is made.
        5.3 Refund Policy
        • Refunds: Refunds will be provided according to the academy’s refund policy, which will be detailed during the enrollment process. Refund eligibility may vary depending on the payment plan selected.
        5.4 Transaction History
        • Transaction History: Students and administrators can access their transaction history through the platform for transparency and record-keeping. This will include dates, amounts, and descriptions of all transactions.
      `,
    },
    {
      subtitle: "Privacy Policies",
      content: `
        6.1 Data Protection
        • Personal Information: wHTa Networks Academy is committed to protecting the privacy of its participants. Personal information will be used only for educational and administrative purposes.
        • Confidentiality: All data shared on the platform will be kept confidential and secure.
        7. Disciplinary Actions
        • Violations: Any violations of the academy policies will be investigated promptly.
        • Consequences: Consequences for policy violations may include warnings, suspension, or expulsion from the academy, depending on the severity of the infraction.
        • Appeals: Participants have the right to appeal disciplinary actions through a formal process.
      `,
    },
  ];

  const privacySections = [
    {
      subtitle: "Introduction",
      content:
        "wHTa Networks is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. By using our website or services, you agree to the terms of this Privacy Policy.",
    },
    {
      subtitle: "Information We Collect",
      content: `
        2.1 Personal Information
        We may collect personal information that you provide to us directly, including but not limited to:
        • Contact Information: Name, email address, phone number, and mailing address.
        • Account Information: Username, password, and other registration details.
        • Payment Information: Credit/debit card details, PayPal account information, and M-Pesa account information.
        • Educational Information: Course enrollments, assignment submissions, assessment results, and other academic records.
        2.2 Non-Personal Information
        We may collect non-personal information about your use of our website and services, including:
        • Device Information: IP address, browser type, operating system, and device identifiers.
        • Usage Information: Pages visited, time spent on pages, links clicked, and other usage data.
        • Cookies and Tracking Technologies: We use cookies and similar technologies to track your activity on our website and improve our services.
      `,
    },
    {
      subtitle: "How We Use Your Information",
      content: `
        3.1 Personal Information
        We use your personal information to:
        • Provide and Manage Services: Enroll you in courses, track your progress, and issue certificates.
        • Communicate with You: Send updates, newsletters, promotional materials, and respond to your inquiries.
        • Process Payments: Handle transactions and manage billing.
        • Improve Our Services: Analyze usage data and gather feedback to enhance our offerings.
        3.2 Non-Personal Information
        We use non-personal information to:
        • Analyze Trends: Understand how our website is used and improve user experience.
        • Conduct Research: Gather insights to optimize our services and marketing strategies.
        • Ensure Security: Monitor and protect against fraudulent activity and security threats.
      `,
    },
    {
      subtitle: "Information Sharing and Disclosure",
      content: `
        4.1 Third-Party Service Providers
        We may share your information with third-party service providers who perform services on our behalf, such as payment processors, email service providers, and analytics providers.
        4.2 Legal Requirements
        We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a subpoena or court order).
        4.3 Business Transfers
        In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the business assets.
      `,
    },
    {
      subtitle: "Your Choices and Rights",
      content: `
        5.1 Access and Correction
        You may access and update your personal information by logging into your account or contacting us directly.
        5.2 Opt-Out
        You may opt out of receiving promotional communications from us by following the unsubscribe instructions included in each email.
        5.3 Data Retention
        We retain your information only for as long as necessary to provide our services, comply with legal obligations, and resolve disputes.
      `,
    },
    {
      subtitle: "Security",
      content: `
        6.1 Security Measures
        We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
        6.2 Data Breaches
        In the event of a data breach, we will notify you in accordance with applicable laws and take appropriate steps to mitigate the impact.
      `,
    },
    {
      subtitle: "Changes to This Policy",
      content: `
        7.1 Updates
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on our website and updating the effective date.
        7.2 Your Responsibility
        It is your responsibility to review this Privacy Policy periodically for any changes. Your continued use of our website or services after any changes constitutes your acceptance of the updated policy.
      `,
    },
  ];

  return (
    <>
      <div className="w-full bg-white-b">
        <div className="flex flex-col items-center">
          <div className="flex flex-col lg:flex-row px-4 sm:px-6 lg:px-10 xl:px-24 lg:items-center mt-10 mb-6 w-full max-w-7xl">
            <h4 className="text-lg text-[#FD00E4] text-center lg:text-left">
              Subscribe to receive exclusive news and listings:
            </h4>
            <div className="flex flex-row border-none p-1 lg:pl-8 justify-center w-full lg:w-auto">
              <form onSubmit={handleSubscribe} className="flex">
                <Input
                  type="email"
                  placeholder="email"
                  inputClass="2"
                  isRequired={true}
                  asteriskClass="1"
                  value={subscribeEmail}
                  onChange={setSubscribeEmail}
                  copyPaste={true}
                />
                <SingleClick
                  buttonClass="8"
                  buttonText="Subscribe"
                  buttonType="submit"
                />
              </form>
            </div>
          </div>
  
          {/* Footer Links Section */}
          <div className="w-full max-w-7xl px-4 sm:px-6 lg:px-10 xl:px-24">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:flex lg:justify-between w-full gap-8">
              <ListLink title="Products" data={productsData} />
              <div className="p-2">
                <h5 className="text-sm font-bold text-black">Legal</h5>
                <ul>
                  {contactUsData.map((item, index) => (
                    <li
                      key={index}
                      className="text-base text-black-d hover:text-[#FD00E4] cursor-pointer"
                      onClick={() => {
                        if (item.text === "Terms of Service") {
                          handleShowModal("Terms of Service", termsSections);
                        } else if (item.text === "Privacy Policy") {
                          handleShowModal("Privacy Policy", privacySections);
                        }
                      }}
                    >
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
              <ListLink title="Let's Connect" data={getInTouchData} />
            </div>
  
            <hr className="h-1 bg-purple-darker mt-6"></hr>
  
            {/* Footer Bottom Section */}
            <div className="flex flex-col md:flex-row justify-between items-center my-6 space-y-6 md:space-y-0">
              <a href="https://www.goodfirms.co/">
                <div className="flex flex-col items-center">
                  <h2 className="text-base lg:text-lg font-semibold text-purple-normal-hover">
                    Top Rated By:
                  </h2>
                  <img
                    src="/icons/GF_Icon.png"
                    alt="GoodFirms Icon"
                    className="w-28 h-12"
                  />
                </div>
              </a>
  
              <div className="text-center md:text-left">
                <span className="font-semibold text-sm">Locations</span>
                <p className="text-gray-700">Kenya</p>
                <p className="text-gray-700">North Airport Rd, Southfield Mall</p>
                <p className="text-gray-700">(+254) 782499113</p>
              </div>
  
              <div className="flex flex-col items-center">
                <div className="flex items-center space-x-2">
                  <img
                    src="/icons/whta.svg"
                    alt="wHTa Icon"
                    className="w-12 h-12"
                  />
                  <span className="text-purple-normal-active font-bold text-2xl lg:text-4xl">
                    wHTa
                  </span>
                </div>
                <h2 className="text-base lg:text-lg text-purple-normal-hover mt-2">
                  Creating Innovative Tech Solutions
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      {/* Modal */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity ${
          showModal ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      ></div>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 transition-transform ${
          showModal ? "translate-y-0" : "translate-y-full"
        }`}
      >
        <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl mx-4 md:mx-0">
          <div className="flex justify-between items-center border-b p-4">
            <h2 className="text-xl font-bold text-purple-600">{modalTitle}</h2>
            <button
              onClick={() => setShowModal(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="p-4 max-h-[70vh] overflow-y-auto">
            {modalSections.map((section, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-lg font-semibold text-purple-300">
                  {section.subtitle}
                </h3>
                <p className="mt-2 whitespace-pre-line">{section.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
  
}

export default Footer2;
