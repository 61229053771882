/* eslint-disable no-unused-vars */
import React from "react";

function Hero1() {
  return (
    <div className="w-full sm:w-3/7 lg:w-3/5 lg:h-full flex items-center pt-24 justify-center">
      <img src="/hero.svg" alt="hero svg" />
    </div>
  );
}

export default Hero1;
